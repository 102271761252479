.Bod {
    margin: 5% auto;
    max-width: 1200px;
}

.daySelector:disabled, .monthPick:disabled, .yearPick:disabled, .genderSelector:disabled {
    background-color: #ffffff00;
}

.progress-3 {
    background-color: #00b0bc ;
    border: solid #00b0bc 8px;
    width: 60%;
}

.FormsTitle {
    text-align: center;
    font-size: 150%;
    font-weight: 600;
    color: #0D6DB7;
    padding-bottom: 3%;
}

.Reasons {
    margin-top: 10%;
    margin-left: 35%;
}

.fControl {
    border: medium #adc7f2 groove;
}

.fGroup {
    text-align: left;
}

.yearPick {
    margin-top: 17%;
    height: 38px;
    width: 100%;
}

.monthPick {
    margin-top: 17%;
    height: 38px;
    width: 100%;
    margin-left: 10%;
}

.RUTinput {
    width: 90%;
}

.fGroupRUT {
    padding-top: 1.4%;
}

.fGroupEmail {
    margin-top: 1.4%;
}

.SubmitForm {
    text-decoration: none;
    color: white;
}

.SubmitButton {
    width: 10%;
    margin-top: 2%;
    font-weight: 600;
    background-color: #488CF4;
    font-weight: bold;
    border-style: hidden;
}

.DivSubmit {
    text-align: center;
    padding-top: 2%;
}

.BackButtCol, .SeeButtCol {
  text-align: center;
  padding-top: 10%;
}

@media (max-width:1199px) {
    .monthPick {
        width: 140%;
    }
}

@media (max-width:991px) {
    .monthPick {
        width: 110%;
        margin-left: 0%;
    }

    .yearPick {
        width: 110%;
    }

    .SubmitButton {
        width: 20%;
    }
}

@media (max-width:767px) {
    .monthPick {
        width: 200%;
        margin-left: 0%;
        margin-top: 0%;
    }

    .yearPick {
        width: 200%;
        margin-top: 0%;
    }

    .fGroup {
        text-align: left;
    }

    .fGroupMonth {
        text-align: left;
    }

    .Bod {
        padding-top: 15%;
    }

    .fGroupRUT {
        padding-top: 5%;
    }

    .fGroupEmail {
        margin-top: 0%;
    }

    .RUTinput {
        width: 100%;
    }
}

@media (max-width:500px) {
    .SubmitButton {
        width: 40%;
    }
}

@media (max-width:400px) {
    .Bod {
        padding-top: 20%;
    }
}
