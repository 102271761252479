.UserBody {
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.UserWelcome {
    padding-left: 10%;
    padding-right: 10%;
    font-weight: 600;
    color: #0D43B7;
    padding-top: 5%;
    padding-bottom: 3%;
}

.ProfileInfo {
    justify-content: center;
    background-color: #00A0BC;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
}

.EnterInfo {
    justify-content: center;
    background-color: #0091BC;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
}

.FirstStack,
.SecondStack,
.ProfileStack {
    padding-top: 1%;
    padding-bottom: 1%;
}

.stack1Item1,
.stack1Item2 {
    text-align: left;
    padding: 2%;
    font-size: 80%;
}


.item1span1 {
    font-size: 170%;
    font-weight: 700;
}

.item1span2 {
    font-size: 120%;
    font-weight: 500;
}

.CompleteInfo {
    background-color: #0D5FB7;
    color: #ffffff;
    font-weight: 700;
    font-size: 130%;
}

.DerivateStack {
    justify-content: center;
    background-color: #ffffff;
    color: #474747;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    padding-left: 1%;
    padding-right: 1%;
}

.stack2Item1 {
    text-align: left;
    font-size: 80%;
}

.stack2Item2 {
    text-align: right;
    font-size: 80%;
}

.stack2item1span1 {
    font-size: 160%;
    font-weight: 600;
}

.stack2item2span1 {
    font-size: 130%;
    font-weight: 600;
    padding-bottom: 2%;
}

.stack2item2span2 {
    font-size: 120%;
}

.RequireStack {
    justify-content: center;
    background-color: #00B0BC;
    color: #ffffff;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1%;
}

.SeeMedicalOrder {
    background-color: #0D51B7;
    color: #ffffff;
    float: right;
    padding-top: 5%;
    padding-bottom: 5%;
    font-weight: 700;
    font-size: 120%;
}

.HeartAge {
    justify-content: center;
    background: rgb(252,151,7);
    background: linear-gradient(90deg, rgba(252,212,7,1) 0%,rgba(252,151,7,1) 80%);
    color: #ffffff;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 7%;
    padding: 2%;
}

.stack3Item1 {
    padding: 5%;
}

.Puter {
    width:300px;
    padding-left: 5%;

}

.stack3item1span1,
.stack3item1span2,
.stack3item1span3 {
    text-align: left;
}

.stack3item1span1 {
    font-size: 130%;
    font-weight: 600;
}

.stack3item1span2 {
    font-size: 170%;
    font-weight: 900;
    color: #0D43B7;
}

.stack3item1span3 {
    font-size: 110%;
    color: #474747;
}

.HeartAgeSpan {
    background-color: white;
    opacity: 0.9;
}

.HeartAgeSpanInner {
    color:#474747;
    font-size: 300%;
    opacity: 2;
}

#container {
    height: 400px;
    width: 95%;
    padding-left: 4%;
}

.ChartStack {
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 3%;
    padding-right: 3%;
    width: 95%;
    background-color: #00B0BC;
    color: #ffffff;
    justify-content: center;
}

.stack4span1 {
    font-size: 140%;
    font-weight: 700;
}

.stack4span2 {
    font-size: 110%;
    font-weight: 500;
}

.anychart-credits {
    display: none;
 }
