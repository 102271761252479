.PrintOrderButton,
.ViewOrderButton {
  font-size: 120%;
  padding: 1%;
  box-shadow: #498cf433 0px 0px 0px 2px, #498cf433 0px 4px 6px -1px,
    #ffffff14 0px 1px 0px inset;
  background-color: #488cf4;
  font-weight: bold;
  border-style: hidden;
  margin: 2%;
  margin-top: 10%;
}

.progress-5 {
  margin-left: 0%;
  background-color: #00b0bc ;
  height: 100%;
  border: solid #00b0bc 8px;
  width: 100%;
}

.Confetti {
  margin-left: 30rem;
}

.ViewOrderDiv {
  text-align: right;
  padding-right: 10%;
}

.stackButtonPDFs {
  padding-top: 2%;
  justify-content: center;
}

.PrintOrderDiv {
  text-align: left;
  text-align: center;
}

.PrintOrderDiv_M {
  text-align: center;
}

.PDF_Utilities {
  justify-content: center;
}


.MailIconButton {
  display: flex;
  color: #478cf4;
  font-size: 50px;
  margin-top: 35px;
  margin-left: 100%;
}

.ThankYouMessage {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #478cf4;
  font-weight: 600;
  font-size: 2.2em;
}
.ThankYouMessageSub {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #478cf4;
  font-weight: 600;
  font-size: 1.5em;
  margin-top: 30px;
}

.BackGround {
  max-width: 1200px;
  margin: auto;
  justify-content: center;
  padding-top: 10%;
  padding-bottom: 18%;
}
.sta {
  grid-row: 1;
}

.utily {
  max-width: 1680px;
  padding-left: 2%;
  padding-right: 2%;
  justify-content: center;
}

@media (max-width: 5000px) {
  .utily {
    max-width: 1680px;
    padding-left: 3%;
  }
}

@media (max-width: 3000px) {
  .utily {
    max-width: 1550px;
    padding-left: 1%;
  }
}

@media (max-width: 1536px) {
  .utily {
    max-width: 1280px;
    padding-right: 4%;
  }
}

/* xl */
@media (max-width: 1200px) {
  .utily {
    max-width: 1024px;
    padding-right: 10%;
  }
}

/* lg */
@media (max-width: 990px) {
  .utily {
    max-width: 768px;
    padding-left: 10%;
  }
}

/* md */
@media (max-width: 768px) {
  .utily {
    padding-left: 6%;
    max-width: 640px;
  }

  .BackGround {
    padding-top: 20%;
  }
}

/* sm */
@media (max-width: 640px) {
  .utily {
    max-width: 475px;
    padding-left: 8%;
  }
}

/* xs */
@media (max-width: 475px) {
  .utily {
    width: 100%;
    padding-left: 3%;
  }

  .BackGround {
    padding-top: 30%;
  }
}

@media (max-width: 320px) {
  .utily {
    width: 100%;
  }
}
