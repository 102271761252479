a:link { text-decoration: none; }
a:active { text-decoration: none; }

.Navigation {
    position: sticky;
    background-color: #034B68;
   
}

.Navbar {
    height: 75px;
    justify-content: end;
    padding: 1rem;
     z-index: 1;
}

.LogoutButton {
    background-color: #02405A;
    border: none;
    &:hover {
        background-color: #023145;
    }
}

.MainPageButton {
    width: 250px;
    text-align: left;
    padding-left: 20px;
    height: 50px;
    padding-top: 10px;
    color: #474747;
}

.ToggleSB {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    margin-bottom: 1%;
    color: #ffffff;
    margin-right: 2%;
}

.SideBar {
    width: 300px;
    position: fixed;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    margin-left: -300px;
    margin-top: 16px;
}

.divSideBar {
    padding-bottom: 20px;
}

#trigger, #burger, #burger:before, #burger:after {
    position: absolute;
    background: #00B0BC;
    width: 30px;
    height: 5px;
    transition: .2s ease;
    cursor: pointer;
    z-index: 1;
}
#trigger {
    height: 25px;
    background: none;
}
#burger:before {
    content: " ";
    top: 10px;
    left: 0;
}
#burger:after {
    content: " ";
    top: 20px;
    left: 0;
}
#menu-toggle:checked ~ .UserBody {
    padding-left: 250px;
}
#menu-toggle:checked ~ body{
    margin-left: 250px;
}
#menu-toggle:checked + #trigger + #burger {
    top: 35px;
    transform: rotate(180deg);
    transition: transform .2s ease;
}
#menu-toggle:checked + #trigger + #burger:before {
    width: 20px;
    top: -2px;
    left: 18px;
    transform: rotate(45deg) translateX(-5px);
    transition: transform .2s ease;
}
#menu-toggle:checked + #trigger + #burger:after {
    width: 20px;
    top: 2px;
    left: 18px;
    transform: rotate(-45deg) translateX(-5px);
    transition: transform .2s ease;
}
#menu {
    left: 0px;
    width: 250px;
    background-color: #fff;
    animation: not-checked-anim .2s both;
    transition: .2s;
    justify-content: left;
}
#trigger #menu {
    margin-left: 200px;
}
#menu-toggle:checked + #trigger + #burger + #menu {
    animation: checked-anim 3s ease both;
}
.ToggleSB:not(:checked), .ToggleSB:checked {
    display: none;
    margin-left: 20px;
}
#menu-toggle:checked ~ .SideBar {
    margin-left: 250px;
}
