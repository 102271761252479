.blob-preview {
  margin: 20px;
  width: 30%;
  text-align: left;
}

.progress-4 {
  background-color: #00b0bc ;
  height: 20%;
  border: solid #00b0bc 8px;
  width: 80%;
}

.ExamsTitle {
  font-size: 200%;
  font-weight: 600;
  text-align: center;
  color: #0D6DB7;
  padding-bottom: 2%;
}

.SpanNames {
  font-size: 100%;
  font-weight: 600;
}

.SubtextExams {
  padding-top: 10%;
}

.CheckboxExams {
  font-size: 200%;
}

.NextButtonExams {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
}

.ExamsHere {
  justify-content: center;
  margin: 5% auto 0% auto;
  max-width: 1200px;
}
