.AllUserContain {
  padding-inline: 5em;
  padding-block: 2em;
  background-color: #e0f3f4;
}

.StackButtonUser {
  margin-block-end: 1em;
}

.AddInterCons, .GenerateCons {
  background-color: #0D6DB7;
  font-weight: 700;
  width: 200px;
  height: 100px;
  font-size: 1.2em;
}

.AddInterCons {
  background-color: #6494b8;
}

.cardUserProf1, .cardUserProf3, .cardUserProf4 {
  background-color: white;
  padding: 1.3em;
  margin-block-end: 1em;
}
.cardUserProf4 {
  padding: 0em;
}

.PFPImage {
  height: 180px;
  width: 180px;
}

.BasicProfileInfo {
  color: #3e474f;
}

.UserNameDiv {
  text-align: center;
}

.UserName {
  font-size: 1.8em;
  font-weight: 700;
  color: #1a4b76;
  margin-inline-start: 1em;
}

.BasicProfileInfoStack {
  padding-block-start: 1em;
  padding-inline-start: 2em;
  line-height: 35px;
}

.UserIdent {
  font-size: 1.2em;
  font-weight: 600;
}

.bluey {
  color: #388085;
}

.NextApp {
  text-align: center;
  color: #1a4b76;
  font-size: 1.4em;
  font-weight: 700;
  margin-inline: 1.5em;
}

.ProfileBox {
  height: 200px;
  width: 280px;
  margin-inline: 0.2em;
  font-size: 1.2em;
  font-weight: 600;
  text-align: center;
  color: #3e474f;
  padding-block-start: 0.4em;
}

.BoxOfHistory {
  color: #1a4b76;
}

.UserProfStack1_1 {
  padding-inline: 1em;
  padding-block-start: 0.5em;
}

.HistoryHighlight {
  font-size: 0.8em;
  color: #388085;
  background-color: #def3ed;
}

.CheckIconStack {
  padding-inline: 1em;
}

.LastEvalBox {
  height: 100px;
  width: 490px;
  margin-block-end: 1.7em;
}

.UltEvStack {
  color: #3e474f;
  line-height: 28px;
}

.UltEv1 {
  font-weight: 600;
  font-size: 1.2em;
}
.UltEv2 {
  color: #0D6DB7;
  font-size: 1.5em;
  font-weight: 700;
}

.IMCStack {
  background-color: #a7e0e3;
  color: #3e474f;
  margin-block: 1em;
  font-size: 1.2em;
  font-weight: 600;
}

.Greenery {
  background-color: rgb(220, 242, 247);
  width: 120px;
  padding-block: 0.2em;
  text-align: center;
  margin-inline-start: 1em;
  margin-inline-end: 0.3em;
}

.DateControl {
  margin-inline-start: 14em;
  text-align: center;
}

.DateItself {
  width: 150px;
  font-size: 0.8em;
  font-weight: 700;
  margin-inline-end: 1em;
}

.PhysDataStack {
  color: #3e474f;
  font-weight: 600;
  font-size: 1.1em;
  line-height: 35px;
}

.RiskIndicators {
  text-align: center;
  font-size: 1.7em;
  font-weight: 700;
  color: #1a4b76;
  margin-block: 0.5em;
}

.RiskIndicatorsBox {
  border: solid #7fa5c8 2px;
}

.RiskTable {
  font-weight: 700;
  margin-inline-end: 1em;
}

.RiskSpan1 {
  font-size: 1.2em;
  width: 200px;
  color: #0D6DB7;
}

.HeaderTags {
  font-size: 1.2em;
  font-weight: 700;
}

.RiskTag1, .RiskTag2, .RiskTag3 {
  width: 100px;
  text-align: center;
}

.RiskSpan3, .RiskSpan4, .RiskSpan5 {
  font-size: 1.2em;
  width: 100px;
  text-align: right;
}

.RiskSpan4, .RiskTag2 {
  color: #00A0BC;
}

.RiskSpan5, .RiskTag3 {
  color: #0D6DB7;
}

.horizontalDivider {
  border-top: solid #98d6e0 1px;
}

.OpenForms {
  font-size: 1.5em;
  padding-inline: 0.7em;
  position: fixed;
  bottom: 1em;
  right: 1em
}

.iFrame {
  border: none; 
  max-width:100%; 
  max-height:100vh;
}