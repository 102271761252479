
.AllAdmin {
  padding: 3em;
  background-color: #e0f3f4;
}

.AdminRow1 {
  padding-bottom: 1em;
}

.bodyAdmin {
  color: #0D6DB7;
  font-weight: 800;
  font-size: 1.3em;
  padding-bottom: 1em;
}

.cardAdmin1, .cardAdmin2, .cardAdmin3, .cardAdmin5 {
  background-color: white;
  padding: 1.3em;
}

.cardAdmin1 {
  height: 330px;
  font-weight: 600;
}

.Bluey {
  color: #0091BC;
}

.cardAdmin3 {
  display: flex;
  height: 330px;
}

#container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.Graphdiv {
  height: 15em;
}

.MotivesInter {
  font-size: large;
  font-weight: 700;
  color: #0D6DB7;
}

.QuantityBars {
  width: 200px;
  height: 1em;
  background-color: #e0f3f4;
}

.QuantityBars2 {
  height: 1em;
  background-color: #00B0BC;
}

.ReasonsStack {
  font-weight: 600;
  color: #474747;
}

.AttentionMes {
  font-size: 0.8em;
  font-weight: 800;
  color: #0D6DB7;
  text-align: center;
}

.HorizonLineAttent {
  border-top: 3px solid #00B0BC;
  margin-block-start: 1.2em;
  margin-block-end: 1.2em;
}

.HorizonLineAttent2 {
  border-top: 3px solid #00B0BC;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.StackPercents {
  height: fit-content;
}

.AttentionN1 {
  font-size: 1.3em;
  font-weight: 700;
  color:#0D6DB7;
}
.AttentionN2 {
  font-size: 1.3em;
  font-weight: 700;
  color: rgb(195, 12, 12);
}
.AttentionN3 {
  font-size: 1.3em;
  font-weight: 700;
  color:#0c5c20
}

.AttentionSidetext {
  text-align: left;
  font-size: 0.7em;
  font-weight: 600;
  height: fit-content;
}

.chartalign {
  justify-content: center;
}

#container2 {
  width: 400px;
  height: 270px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

#container3 {
  width: 500px;
  height: 270px;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.ObesityTitle {
  font-size: 1.3em;
  font-weight: 600;
  color: #0D6DB7;
}

.StackGeneralInfo {
  padding-block: 2em;
  font-weight: 700;
  font-size: 0.9em;
  color: #474747;
}

.VerticLineInfo {
  border-left: 3px solid #0D51B7;
  height: 50px;
  margin-inline-start: 1em;
  margin-inline-end: 1em;
}

.rutAdminInput {
  margin-block-start: 1em;
  width: 17em;
  text-align: center;
}

.UserSearch {
  font-weight: 600;
  background-color: #0D51B7;
  width: 17em;
}

.cardAdmin5 {
  padding: 2em;
}

.ExportSelection {
  font-weight: 600;
  background-color: #00B0BC;
  width: 17em;
}

.TableInfo {
  margin-block-start: 2em;
}
