.PackCard {
  width: 20rem;

}

.Packs, .ButtonsPack {
  justify-content: center;
 
}

.ButtonsPack {
  text-align: center;
}

.NextBeforeButtons {
  text-align: center;
}