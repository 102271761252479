.Backgrounds {
    text-align: center;
    color: #0D6DB7;
    font-weight: 600;
    padding-bottom: 2%;
}

.cos {
    padding: 0%;
    margin: 0%;
    border: 0%;
}

.ToggleButtonsIFPage {
    margin: 2%;
    width: 120%;
    
}

.ToggleButtonIFPage {
    width: 80%;
    height: 3rem;
    font-weight: 600;
    font-size: 70%;
    color: #488CF4;
    border-color: #488CF4;
    box-shadow: #498CF433 0px 0px 0px 2px, #498CF433 0px 4px 6px -1px, #ffffff14 0px 1px 0px inset;
}

.ToggleButtonIFPage:hover,
.mButt:hover {
    background-color: #DDEAFF;
    box-shadow: #488CF4 0px 7px 10px 0px;
}

.ToggleButtonIFPage:active
 {
    color: white;
    background-color: #488CF4;
    font-weight: bold;
    box-shadow: #488CF4 0px 7px 10px 0px;
}

.FamilyMatters {
    justify-content: center;
}


.cards {
    margin: 2%;
}







