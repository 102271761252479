.UpperMedicRow {
  margin-block-end: 2em;
}

.avatar {
  height: 25rem;
  width: auto;
  }


.BodyInter {
  padding-top: 0%;
  margin-top: 0%;
}

.imgMedicine {
  width:35%;
}

.buttonMedic{
width:23rem;
}

.StackStack {
margin-top: 5%;

}

.noApto{
  padding: 5%;
  height: fit-content;
}

.TableNoApt {
  font-size: 1rem;
}

.CardTable {
  border: none;
}

.CardTable {
  width:fit-content;
}

.InfoBorder {
  border-bottom: #474747 solid 1px;
  margin-bottom: 3%;
  padding-bottom: 3%;
}

.CardUser {
  height:35rem;
  padding: 5%;
}


h5, h4 {
  margin: 0%;
 
}

h5 {
  font-size: 100%;
  font-weight: 600;
  
}
h4 {
  font-size: 130%;
  font-weight: 700;
}

.StackInfo {
  color: #474747;
  width: 100%;
}

h6 {
  font-size: 80%;
  font-weight: 400;
  color: #474747;
  
}


.InfoSubTitles {
  font-weight: 600;
}

.Antecedentes{
border-radius: 13% 13% 13% 13%;
  font-size: 70%;
  padding: 2%;
  background-color: #e0f3f4;
}

.CardMedicine {
  width: 100%;
  height: 15rem;
 
}

.box {
  height: 34rem;
}

.ContainerUser{
  padding: 5%; 
 
  margin: 0%;
}
.imgCardMedicine {
  width:30%;
  padding: 1rem;
}

.IMCcard {
  background-color: #A2DFE4;
  border: none;
}

.StackUser, .StackInfo {
  width: auto;
}
.ColLeft{
  width: 50rem;
  overflow: hidden;
}
.ColRight{
  width: 80rem;
}

.StackInfo {
  padding-top: 5%;
  padding-bottom: 5%;
  color: #0d6db7;
  width: 100%;
}

.GraphMedicine {
  height: 31rem;
}

.ProfileGraph1 {
  width:auto;
  height: 350px;
  margin-block-start: 2em;
  margin-inline-end: 1em;
}

.ProfileGraph2 {
  width: fit-content;
  justify-content: center;
  text-align: center;
  padding-left: 15%;
}

.nombretabla {
  text-align: center;
  color: #0B486B;

}


.colorestabla {
  color: #0d6db7;

}

.controlactual {
  color: #19B0BC;

}


.Rangooptimo {
  color: #0E6DB7;

}


.UltimoControl {
  color: #2D2D2D;
}

.subtitlettable
{
font-weight: 700;
color: #2D2D2D ;
}


.subtitlettablemidel
{
font-weight: 700;
color: #19B0BC;
}



.subtitlettablleft
{
font-weight: 700;
color: #0E6DB7 
;
}


.RhythmCardiac {
  text-align: center;
  color: #0D6DB7;
}
