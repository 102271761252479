.blob-preview {
    width: 30%;
  }
  
  .progress-4 {
    background-color: #00b0bc ;
    height: 20%;
    border: solid #00b0bc 8px;
    width: 80%;
  }
  

  .SpanNames {
    font-size: 100%;
    font-weight: 600;
  }
  
  .SubtextExams {
    padding-inline-start: 2em;
  }
  
  .CheckboxExams {
    font-size: 1%;
    font-weight: 600;
    color: #0D43B7;
    padding-block-start: 0.5em;
    padding-block-end: 0.5em;
  }
  
  .NextButtonExams {
    text-align: center;
  }
  
  .ExamsHere {
    justify-content: center;
    margin: 5% auto 0% auto;
    max-width: 1200px;
  }

  .CheckStacks {
    justify-content: center;
    font-size: 50%;
  }