.Butts {
    margin-top: 10%;
    margin-bottom: 10%;
    text-align: center;
}

.Butt1,
.Butt2 {
    font-size: 100%;
    padding: 2%;
    padding-left: 6%;
    padding-right: 6%;
    font-weight: bold;
    border-style: hidden;
    box-shadow: #498CF433 0px 0px 0px 2px, #498CF433 0px 4px 6px -1px, #ffffff14 0px 1px 0px inset;
}

.Butt1 {
    background-color: #DDEAFF;
}

.Butt2 {
    background-color: #488CF4;
}

.Butt1:hover {
    background-color: #488CF4;
}

@media (max-width:767px) {

    .Butt1,
    .Butt2 {
        font-size: 120%;
    }

    .co1 {
        margin-right: 0%;
        margin-left: 0%;
    }
}
