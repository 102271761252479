.logincontainer {
  height: 100%;

  padding-top: 5%;
}

.ErrorRut {
  margin-right: -5%;
  margin-top: 4%;
  margin-left: 1%;
}

.CardLogin {
  color: white;
  border-style: hidden;
}
.CardBodyLogin {
  padding: 5%;
  text-align: center;
  background-color: #0d6db7;
  border-radius: 5% 5% 5% 5%;
  background: linear-gradient(to bottom, #0d6db7, #0d51b7);
}

h2 {
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.message {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5%;
  margin-top: -4%;
}

.RutForm,
.passwordForm {
  outline: white;
  margin-bottom: 4%;
  margin-left: 5%;
  margin-right: 5%;
}

.form-control-login {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: solid;
  border-radius: 0.25rem;
  width: 100%;

  &:focus {
    color: white;
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    ~ .form-label-login {
      top: -3rem;
      color: white;
      font-size: 70%;
      background-color: #0e6db7;
      padding-left: 1%;
      padding-right: 1%;
    }
  }
}

.form-control-login:not(:placeholder-shown) ~ .form-label-login {
  top: -3rem;
  color: white;
  font-size: 70%;
  background-color: #0e6db7;
  padding-left: 1%;
  padding-right: 1%;
}

.form-label-login {
  color: rgb(255, 255, 255);
  position: relative;
  pointer-events: none;
  font-size: 80%;
  left: -11rem;
  top: -2rem;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.FormRut {
  left: -12rem;
}

.loginbutton {
  color: white;

  background-color: #00b0bc;
  text-transform: uppercase;
  padding: 2% 5%;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
/* 
  .text-white-50 {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 800;
  }
 Pseudo-clases para los enlaces
.text-white-50:hover {
    color: rgba(255, 255, 255, 0.75);
  }

 */

.loginbutton:hover {
  transition: all 0.3s;
  opacity: 50%;
}
